import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    location: "入职资料", //menu textK
    show: false, //menu
    info: {
      //微信用户信息
      // headImgUrl:
      //   "https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83er7iceCAwP62CfnVpWNgibxia9kG1EjiaomiapDjmIJvj8K0uSstkGbkM1V2EfPaDZAcA9iaibCff8OyYTFg/132",
      // nickName: "Coast",
      // openId: "opigK42t4_ftK-cEZa60IAgXktLo",
      // postName: null,
      // re_empstatus: -1,
      // realName: null,
      // sex: 0,
    },
    guideKey: true,
    shareKey: false,
    activeTabKey: "",
    leaveImg: "",
    loseImg: "",
  },
  mutations: {
    changeLocation(state, word) {
      state.location = word;
    },
    SaveInfo(state, item) {
      state.info = item;
    },
    changeNav(state, key) {
      state.guideKey = key;
    },
    changeShare(state, key) {
      state.shareKey = key;
    },
    SaveActiveTab(state, val) {
      state.activeTabKey = val;
    },
    SaveLeaveImg(state, val) {
      state.leaveImg = val;
    },
    SaveLoseImg(state, val) {
      state.loseImg = val;
    },
  },
  actions: {
    changeLocation({ commit }, word) {
      commit("changeLocation", word);
    },
    SaveInfo({ commit }, item) {
      commit("SaveInfo", item);
    },
    changeNav({ commit }, key) {
      commit("changeNav", key);
    },
    changeShare({ commit }, key) {
      commit("changeShare", key);
    },
    SaveActiveTab({ commit }, val) {
      commit("SaveActiveTab", val);
    },
    SaveLeaveImg({ commit }, val) {
      commit("SaveLeaveImg", val);
    },
    SaveLoseImg({ commit }, val) {
      commit("SaveLoseImg", val);
    },
  },
  modules: {},
});
export default store;
